import { useSettings } from '@wix/tpa-settings/react';
import { useMemo, useState } from 'react';
import type { ItemImage, PopulatedMenu } from 'root/apiTypes';
import { getMenusToDisplay } from './utils';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import { settingsParams } from 'root/components/Menus/settingsParams';
import type { MenusDisplayOptions } from 'root/components/Menus/Settings/Tabs/MenusTab/consts';

export const useMenusLogic = ({
  menus = [],
  initialActiveTab,
}: {
  menus: PopulatedMenu[];
  initialActiveTab: number;
}) => {
  const settings = useSettings();
  const menusSideImage = settings.get(settingsParams.menusSideImage) as ItemImage;
  const menusDisplayOrder = settings.get(settingsParams.menusDisplayOrder!) as MenuOrder[];
  const menusDisplayOption = settings.get(settingsParams.menusDisplayOption) as MenusDisplayOptions;

  const displayableMenus = useMemo(() => {
    if (!menusDisplayOrder) {
      return menus;
    }
    return getMenusToDisplay({ menusDisplayOrder, menus, menusDisplayOption });
  }, [menus, menusDisplayOrder, menusDisplayOption]);

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const shouldShowTabs = displayableMenus.length > 1;

  return {
    menusSideImage,
    activeTab,
    displayableMenus,
    shouldShowTabs,
    menusDisplayOrder,
    setActiveTab,
  };
};
