import type { DisplayTabEntity, DisplayTabFields } from './clientTypes';
import type { FieldName } from './consts';

export const dataHooks = {
  app: {
    container: 'app.container',
  },
  menus: {
    container: 'menus.container',
    menuState: {
      container: 'menus.menu-state.container',
      content: 'menus.menu-state.content',
    },
  },
  menu: {
    container: 'menu.container',
    name: 'menu.name',
    description: 'menu.description',
    sideImage: 'menu.sideImage',
    mobileSideImage: 'menu.mobileSideImage',
  },
  sections: {
    container: 'sections.container',
  },
  section: {
    container: 'section.container',
    name: 'section.name',
    description: 'section.description',
  },
  items: {
    container: 'items.container',
  },
  item: {
    container: 'item.container',
    name: 'item.name',
    description: 'item.description',
    topDivider: 'item.top-divider',
    bottomDivider: 'item.bottom-divider',
    labels: 'item.labels',
    label: 'item.label',
    image: 'item.image',
    price: 'item.price',
    priceVariants: 'item.priceVariants',
    variant: 'item.variant',
  },
  variant: {
    name: 'variant.name',
    price: 'variant.price',
  },
  navigation: {
    container: 'navigation.container',
    tabs: 'navigation.tabs',
    dropdown: 'navigation.dropdown',
  },
  settings: {
    tabs: 'settings.tabs',
    layoutTab: 'settings.layout-tab',
    manageTab: 'settings.manage-tab',
    designTab: 'settings.design-tab',
    displayTab: 'settings.display-tab',
    styleAITab: 'settings.style-ai-tab',
    menusTab: 'settings.menus-tab',
    drillInItem: (itemName: string) => `settings.drill-in-item.${itemName}`,
    drillInItemTitle: (itemName: string) => `settings.drill-in-item.title.${itemName}`,
    drillInItemIconButton: (itemName: string) => `settings.drill-in-item.icon-button.${itemName}`,
    resetButton: 'settings.reset-button',
    studioChangesBanner: 'settings.studio-changes-banner',
  },
  fields: {
    thumbnailPicker: {
      fieldSet: (field: string) => `fields.thumbnailPicker.fieldSet.${field}`,
      thumbnail: (field: string, value: number) => `fields.thumbnailPicker.thumbnail.${field}.${value}`,
    },
    presetSelect: {
      fieldSet: (index: number) => `fields.presetSelect.fieldSet.${index}`,
      thumbnail: (field: string) => `fields.presetSelect.thumbnail.${field}`,
    },
    overflowField: {
      fieldSet: `fields.overflowField.fieldSet`,
      thumbnail: (field: string) => `fields.overflowField.thumbnail.${field}`,
    },
    borderPicker: {
      formField: (field?: FieldName) => `fields.borderPicker.formField.${field}`,
      baseBorderPicker: (position: string, field?: FieldName) =>
        `fields.borderPicker.baseBorderPicker.${position}.${field}`,
      baseBorderInput: (field?: FieldName) => `fields.borderPicker.baseBorderInput.${field}`,
      baseColorPicker: (field?: FieldName) => `fields.borderPicker.baseColorPicker.${field}`,
      toggleButton: (field?: FieldName) => `fields.borderPicker.toggleButton.${field}`,
    },
    slider: {
      fieldSet: (field?: FieldName) => `fields.slider.${field}.fieldSet`,
      slider: (field?: FieldName) => `fields.slider.${field}.slider`,
      input: (field?: FieldName) => `fields.slider.${field}.input`,
    },
    numberInput: {
      label: (field: FieldName) => `fields.numberInput.${field}.label`,
      input: (field: FieldName) => `fields.numberInput.maxItems.${field}.input`,
    },
    toggleSwitch: {
      text: (field: FieldName) => `fields.toggleSwitch.${field}.text`,
      toggle: (field: FieldName) => `fields.toggleSwitch.${field}.toggle`,
    },
    radioGroup: {
      field: (field: string) => `fields.radioGroup.field.${field}`,
      group: (field: string) => `fields.radioGroup.${field}`,
      radio: (index: number, field: string) => `fields.radio.${field}.${index}`,
    },
    backButton: {
      button: 'fields.backButton.button',
    },
    cornerRadius: {
      formField: (field: string) => `fields.cornerRadius.formField.${field}`,
      cornerRadiusInput: (field: string) => `fields.cornerRadius.cornerRadiusInput.${field}`,
    },
    dropdown: {
      formField: (field: string) => `fields.dropdown.formField.${field}`,
      dropdown: (field: string) => `fields.dropdown.dropdown.${field}`,
    },
    segmentedToggle: {
      formField: (field: string) => `fields.segmentedToggle.formField.${field}`,
      toggle: (field: string) => `fields.segmentedToggle.toggle.${field}`,
      option: (value: string) => `fields.segmentedToggle.option.${value}`,
    },
    fontAndColorPicker: (field: string) => `fields.fontAndColorPicker.${field}`,
    opacityColorPicker: (field: string) => `fields.opacityColorPicker.${field}`,
    colorPicker: (field: string) => `fields.colorPicker.${field}`,
  },
  manageTab: {
    container: 'settings.manage-tab.container',
    emptyState: 'settings.manage-tab.empty-state',
    manageMenusButton: 'settings.manage-tab.manage-menus-button',
    addMenuPageButton: 'settings.manage-tab.add-menu-page-button',
  },
  styleAiTab: {
    previewScreenContainer: 'style-ai-tab.preview-screen.container',
    progressBar: 'style-ai-tab.preview-screen.progress-bar',
    progressBarLabel: 'style-ai-tab.preview-screen.progress-bar-label',
    skeletonGroup: 'style-ai-tab.preview-screen.skeleton-group',
    previewTitle: 'style-ai-tab.preview-screen.title',
    previewContainer: 'style-ai-tab.preview-screen.container',
  },
  menusTab: {
    container: 'settings.menus-tab.container',
    title: 'settings.menus-tab.title',
    displayOptions: 'settings.menus-tab.display-options',
    displayAll: 'settings.menus-tab.display-options.all',
    displaySpecific: 'settings.menus-tab.display-options.specific',
    menusList: 'settings.menus-tab.menus-list',
    menuListRow: (index: string) => `settings.menus-tab.menus-list.row.${index}`,
    menuName: (index: string) => `settings.menus-tab.menus-list.menu-name.${index}`,
    menuListRowActions: (index: string) => `settings.menus-tab.menus-list.actions.${index}`,
    draftMenuIcon: (index: string) => `settings.menus-tab.menus-list.draft-menu.icon.${index}`,
    manageMenus: 'settings.menus-tab.manage-menus',
    manageMenusLink: 'settings.menus-tab.manage-menus.link',
  },
  displayTab: {
    container: 'settings.display-tab.container',
    multiMenusTitle: 'settings.display-tab.multiMenusTitle',
    multiMenusTabsCheckbox: 'settings.display-tab.tabs.checkbox',
    formFieldLabel: (entity: DisplayTabFields) => `settings.display-tab.formField-label.${entity}`,
    nameCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.name.checkbox.${entity}`,
    descriptionCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.description.checkbox.${entity}`,
    priceCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.price.checkbox.${entity}`,
    currencyCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.currency.checkbox.${entity}`,
    labelsCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.labels.checkbox.${entity}`,
    labelsNameCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.labelsName.checkbox.${entity}`,
    labelsIconCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.labelsIcon.checkbox.${entity}`,
    imageCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.image.checkbox.${entity}`,
    dividersCheckbox: (entity: DisplayTabEntity) => `settings.display-tab.dividers.checkbox.${entity}`,
    pricingOptionsRadioGroup: 'settings.display-tab.section.pricingOptions.radio-group',
    pricingOptionsShowZeroText: 'settings.display-tab.section.pricingOptions.show-zero.text',
    pricingOptionsSpecificMenusRadioText: 'settings.display-tab.section.pricingOptions.radio.specific-menus.text',
    pricingOptionsAllMenusRadioText: 'settings.display-tab.section.pricingOptions.radio.all-menus.text',
    imagePlaceholderRadioGroup: 'settings.display-tab.section.imagePlaceholder.radio-group',
    imagePlaceholderShowPlaceholderText: 'settings.display-tab.section.imagePlaceholder.show-placeholder.text',
    imagePlaceholderShowPlaceholderToggle: 'settings.display-tab.section.imagePlaceholder.show-placeholder.toggle',
    imagePlaceholderSpecificMenusRadioText: 'settings.display-tab.section.imagePlaceholder.radio.specific-menus.text',
    imagePlaceholderAllMenusRadioText: 'settings.display-tab.section.imagePlaceholder.radio.all-menus.text',
    imageViewerChangeActionButton: 'settings.display-tab.section.image-viewer.change-action.button',
    imageViewerChangeActionTooltip: 'settings.display-tab.section.image-viewer.change-action.tooltip',
    imageViewerResetActionButton: 'settings.display-tab.section.image-viewer.reset-action.button',
    imageViewerResetActionTooltip: 'settings.display-tab.section.image-viewer.reset-action.tooltip',
    imageViewer: 'settings.display-tab.section.image-viewer',
    sideImageTitle: 'settings.display-tab.section.side-image.title',
    sideImageShowText: 'settings.display-tab.section.side-image.show-text',
    sideImageShowToggle: 'settings.display-tab.section.side-image.show-toggle',
    menuCheckbox: (id: string) => `settings.display-tab.menus.checkboxes.${id}`,
  },
  layoutTab: {
    container: 'settings-tab.layout-tab.container',
    navigation: {
      sectionHeader: 'settings-tab.layout-tab.navigation.header',
    },
  },
  designTab: {
    container: 'settings-tab.design-tab.container',
    navigation: {
      tabsHeader: 'settings-tab.design-tab.navigation.tabs-header',
      tabStatesHeader: 'settings-tab.design-tab.navigation.tab-header',
      statesTabs: 'settings-tab.design-tab.navigation.states-tabs',
      dropdownHeader: 'settings-tab.design-tab.navigation.dropdown-header',
    },
    menus: {
      header: 'settings-tab.design-tab.menus.header',
    },
    sections: {
      header: 'settings-tab.design-tab.sections.header',
    },
    items: {
      header: 'settings-tab.design-tab.items.header',
    },
    variants: {
      header: 'settings-tab.design-tab.variants.header',
    },
    labels: {
      header: 'settings-tab.design-tab.labels.header',
      iconHeader: 'settings-tab.design-tab.labels.iconHeader',
      spacingHeader: 'settings-tab.design-tab.labels.spacingHeader',
    },
  },
};

export const getDataHook = (dataHook: string) => `[data-hook="${dataHook}"]`;
