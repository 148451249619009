import React from 'react';
import type { FC } from 'react';
import type { ILocation, WidgetProps } from '@wix/yoshi-flow-editor';
import { Menu } from 'root/components/Menus/Menu/Menu';
import type { PopulatedMenu } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import type { NavigateToFn } from 'root/clientTypes';
import { useMenusLogic } from './Widget.logic';
import { AppWrapper } from 'root/components/Menus/AppWrapper/AppWrapper';
import { classes, st } from './Widget.st.css';
import { Navigation } from 'root/components/Menus/Navigation/Navigation';
import { MenuState } from 'root/components/Menus/MenuState/MenuState';
import { WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { DEFAULT_MENUS_SIDE_IMAGE } from 'root/assets/images/MenusSideImage';

export type ControllerProps = {
  menus: PopulatedMenu[];
  navigateTo: NavigateToFn;
  showErrorState?: boolean;
  url: ILocation['url'];
  fitToContentHeight: true;
  initialActiveTab: number;
};

export const Menus: FC<WidgetProps<ControllerProps>> = ({
  menus,
  navigateTo,
  showErrorState,
  url,
  initialActiveTab,
}) => {
  const { activeTab, displayableMenus, shouldShowTabs, setActiveTab, menusSideImage } = useMenusLogic({
    menus,
    initialActiveTab,
  });

  if (showErrorState) {
    return <MenuState content="component.menu.error-state" />;
  }
  return displayableMenus?.length > 0 ? (
    <div>
      <div className={st(classes.root)} data-hook={dataHooks.menus.container}>
        <div className={classes.imageContainer}>
          <WowImage
            shouldUseLQIP
            className={classes.sideBySideImage}
            data-hook={dataHooks.menu.sideImage}
            resize={ImageResizeOptions.cover}
            sourceHeight={menusSideImage?.height}
            sourceWidth={menusSideImage?.width}
            src={menusSideImage.url ?? DEFAULT_MENUS_SIDE_IMAGE}
          />
        </div>
        {shouldShowTabs && (
          <div className={classes.navigationContainer}>
            <Navigation
              activeItem={activeTab}
              currentUrl={url}
              menus={displayableMenus}
              navigateTo={navigateTo}
              setActiveItem={setActiveTab}
            />
          </div>
        )}
        <div className={classes.menuContent}>
          <Menu menu={displayableMenus[activeTab]} />
        </div>
      </div>
    </div>
  ) : (
    <MenuState content="component.menu.empty-state" />
  );
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  return (
    <AppWrapper>
      <Menus {...props} />
    </AppWrapper>
  );
};
